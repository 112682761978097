// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--heading-color);
  border-radius: 1rem 0 1rem 0;
}

.frame.frame-bottom::before,
.frame.frame-top::after {
  content: '';
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 50%;
  border-radius: 9999px;
  transform: translateX(-50%);
  border: 1px solid var(--frame-color);
  background-color: var(--replace-inside-color);
  box-sizing: content-box;
}
.frame.frame-top::after {
  bottom: calc(-1.75rem - 2px);
  clip-path: inset(0 0 calc(50% + 0.48rem) 0);
}
.frame.frame-bottom::before {
  top: calc(-1.75rem - 2px);
  clip-path: inset(calc(50% + 0.5rem) 0 0 0);
}

@media (max-width: 520px) {
  .from-balance {
    display: none;
  }
  .mobile-from-balance {
    display: flex;
  }
}
`, "",{"version":3,"sources":["webpack://./src/domain/main/frame.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,WAAW;EACX,gCAAgC;EAChC,4BAA4B;AAC9B;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,qBAAqB;EACrB,2BAA2B;EAC3B,oCAAoC;EACpC,6CAA6C;EAC7C,uBAAuB;AACzB;AACA;EACE,4BAA4B;EAC5B,2CAA2C;AAC7C;AACA;EACE,yBAAyB;EACzB,0CAA0C;AAC5C;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".heading::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: -1;\n  background: var(--heading-color);\n  border-radius: 1rem 0 1rem 0;\n}\n\n.frame.frame-bottom::before,\n.frame.frame-top::after {\n  content: '';\n  width: 2.5rem;\n  height: 2.5rem;\n  position: absolute;\n  left: 50%;\n  border-radius: 9999px;\n  transform: translateX(-50%);\n  border: 1px solid var(--frame-color);\n  background-color: var(--replace-inside-color);\n  box-sizing: content-box;\n}\n.frame.frame-top::after {\n  bottom: calc(-1.75rem - 2px);\n  clip-path: inset(0 0 calc(50% + 0.48rem) 0);\n}\n.frame.frame-bottom::before {\n  top: calc(-1.75rem - 2px);\n  clip-path: inset(calc(50% + 0.5rem) 0 0 0);\n}\n\n@media (max-width: 520px) {\n  .from-balance {\n    display: none;\n  }\n  .mobile-from-balance {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
