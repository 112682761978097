import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './select.css';
import { Option, Select } from '@material-tailwind/react';
import { useEffect, useRef, useState } from 'react';
import triangle from '@/assets/new-icon/main/triangle.svg';
import triangleDark from '@/assets/new-icon/main/triangle-dark.svg';
import { useIsDarkModeContext, useTradeTokenTypeIndexContext } from '@/context';
import { currencies } from '@/constants';
import { isMobileDevice } from '@/utils';
const Header = ({ isApproved }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const { setTradeTokenTypeIndex } = useTradeTokenTypeIndexContext();
    const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);
    const toggleDropdown = () => setIsOpen((prev) => !prev);
    const handleCurrencyChange = (label) => {
        const selected = currencies.find((currency) => currency.label === label);
        if (selected) {
            setSelectedCurrency(selected);
            setTradeTokenTypeIndex(selected.tokenTypeIndex);
        }
        setIsOpen(false);
    };
    // outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "text-h1 font-medium text-center w-full", children: "Send" }), _jsx("div", { ref: selectRef, children: _jsx(Select, { id: "currency-select", className: "currency-select z-10 w-[133px] mobile:w-[102px] h-10 mobile:h-8 mobile:max-h-8 right-0 text-label_l mobile:text-label_m font-medium bg-secondary-0 dark:bg-secondary-900 dark:border-neutral-700 rounded-8", arrow: _jsx("div", { className: `${isOpen && 'mr-[-12px]'} token-arrow`, children: _jsx("img", { className: "open_indicator", src: isDarkMode ? triangleDark : triangle, style: {
                                width: '12px',
                                height: '9px',
                            }, alt: "Open Select" }) }), value: selectedCurrency.label, onChange: (label) => handleCurrencyChange(label), tabIndex: 0, "aria-label": "Select a currency", disabled: isApproved, "aria-disabled": isApproved, onClick: toggleDropdown, children: currencies.map(({ img, label, description }) => (_jsxs(Option, { value: label, className: `flex items-center min-h-[2.5rem] ${label === selectedCurrency.label && 'hidden'}`, "aria-label": label, children: [img && (_jsx("div", { className: `${isOpen ? 'border-none' : 'border rounded-100 border-neutral-800 ml-[-4px]'} mr-2`, children: _jsx("img", { alt: "Currency Icon", style: {
                                        width: isMobileDevice() ? '30px' : '38px',
                                        height: isMobileDevice() ? '30px' : '38px',
                                    }, className: `${isOpen && 'mobile:ml-1'} inline`, src: img }) })), description ? (_jsxs("div", { className: "flex flex-col items-start", children: [_jsx("span", { className: "text-label_l mobile:text-label_m font-medium", children: label }), _jsx("span", { className: "text-label_s font-medium text-neutral-400 dark:text-neutral-200", children: description })] })) : (_jsx("span", { className: "text-label_l mobile:text-label_m font-medium", children: label }))] }, label))) }) })] }));
};
export default Header;
