import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Token, Util } from '@pheasant-network/pheasant-sdk';
import { useEffect, useState } from 'react';
import jito from '@/assets/logo/eye/jito.svg';
import buttonCheck from '@/assets/new-icon/button-check.svg';
import check from '@/assets/new-icon/check.svg';
import checkDark from '@/assets/new-icon/check-dark.svg';
import arrow from '@/assets/new-icon/external-link-arrow.svg';
import arrowDark from '@/assets/new-icon/external-link-arrow-dark.svg';
import processingGif from '@/assets/new-icon/gif/processing.gif';
import processingPause from '@/assets/new-icon/processing-pause.png';
import { PhaseType, useCurrentTxInfo, useIsDarkModeContext, usePhaseContext, useSelectedNetworkContext, useTradeTokenTypeIndexContext, } from '@/context';
import DetailRowWithIcon from '@/domain/main/DetailRowWithIcon';
import { getExplorerUrl, getImage, getSelectableNetwork } from '@/utils';
import usePollingCctpStatusWithApi from '@/hooks/usePollingCctpStatusWithApi';
import { useNetworkManagerContext } from '@/context/NetworkManagerContext';
const ProcessingModalContent = ({ executeTrade }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const { tradeTokenTypeIndex } = useTradeTokenTypeIndexContext();
    const { currentTxInfo } = useCurrentTxInfo();
    // const currentTxInfo = {
    //   address: 'XXXXXXX',
    //   status: 0,
    //   timestamp: Date.now(),
    //   txhash: '0x',
    //   destCode: 0,
    //   acceptTxHash: '0x',
    //   acceptedTxHash: '0x',
    //   newTradeTxHash: undefined,
    //   burnTxHash: undefined,
    //   mintTxHash: undefined,
    //   amount: '500000000000000000',
    //   fee: '50000000000000',
    //   sourceNetworkId: 11155111,
    //   destinationNetworkId: 534351,
    //   chainName: '',
    //   tokenTypeIndex: Token.ETH,
    //   estimateTime: { minutes: 1, seconds: 0 },
    //   tradeType: 0,
    //   approveTxHash: undefined,
    // }
    const { phase, setPhase } = usePhaseContext();
    const { selectedFromNetwork, selectedToNetwork } = useSelectedNetworkContext();
    const [time, setTime] = useState({
        minutes: currentTxInfo.estimateTime.minutes,
        seconds: currentTxInfo.estimateTime.seconds,
    });
    useEffect(() => {
        if ((phase !== PhaseType.SENT &&
            phase !== PhaseType.DEPOSITED &&
            phase !== PhaseType.DOWNWARD_ETH_SENT) ||
            (time.minutes === 0 && time.seconds === 0))
            return;
        const timerId = setInterval(() => {
            setTime((prevTime) => {
                const { minutes, seconds } = prevTime;
                if (seconds > 0) {
                    return { minutes, seconds: seconds - 1 };
                }
                else if (minutes > 0) {
                    return { minutes: minutes - 1, seconds: 59 };
                }
                else {
                    clearInterval(timerId);
                    return prevTime;
                }
            });
        }, 1000);
        return () => clearInterval(timerId);
    }, [phase, time]);
    usePollingCctpStatusWithApi();
    const networkManager = useNetworkManagerContext();
    return (_jsxs("div", { className: "w-full flex-col", children: [(phase === PhaseType.APPROVING ||
                phase === PhaseType.APPROVED ||
                phase === PhaseType.SENDING ||
                phase === PhaseType.SENT) && (_jsxs("div", { className: `w-full flex flex-col justify-between items-center rounded-12 p-6 mt-8 bg-secondary-10 dark:bg-neutral-900 ${phase === PhaseType.APPROVING
                    ? 'border-2 border-primary-500'
                    : 'border-none'}`, children: [phase === PhaseType.APPROVING && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex", children: [_jsx("div", { className: "loader-modal mr-1" }), _jsx("div", { className: "text-label_l font-medium dark:text-neutral-0", children: "Please approve on your wallet" })] }), _jsx("div", { children: _jsx(DetailRowWithIcon, { tooltipContentIcon: jito, left: _jsx("label", { htmlFor: "approve", children: _jsx("span", { className: "text-label_m font-normal dark:text-neutral-0", children: "Why approve?" }) }), right: _jsx(_Fragment, {}), tooltipContent: _jsxs("div", { className: "flex flex-col items-center", children: [_jsx("p", { className: "typo text-justify text-paragraph_m font-normal p-4 dark:text-neutral-0", children: "Approve transaction gives the Pheasant protocol permission to transfer that token from your wallet." }), _jsx("div", { className: "self-start pl-4", children: _jsxs("a", { href: "https://docs.pheasant.network/protocol/faq#what-is-an-approval-transaction", target: "_blank", rel: "noreferrer", className: "underline text-text_link_m font-normal hover:no-underline", children: ["Read more", _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "14px", height: "14px" })] }) })] }) }) })] })), phase !== PhaseType.APPROVING && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? checkDark : check, alt: "check", style: {
                                            width: `1.25rem`,
                                            height: `1.35rem`,
                                        } }), _jsx("div", { className: "text-label_l font-medium dark:text-neutral-0", children: "Approved on your wallet" })] }), currentTxInfo.approveTxHash && (_jsx("div", { children: _jsxs("a", { href: currentTxInfo.approveTxHash, target: "_blank", rel: "noreferrer", className: "underline text-text_link_m font-normal hover:no-underline dark:text-neutral-0", children: ["View on explorer", _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline ml-1", width: "12px", height: "12px" })] }) }))] }))] })), (phase === PhaseType.APPROVING ||
                phase === PhaseType.APPROVED ||
                phase === PhaseType.SENDING ||
                phase === PhaseType.SENT) && (_jsxs("div", { className: `w-full flex justify-center items-center rounded-12 p-6 mt-2 bg-secondary-10 dark:bg-neutral-900 ${phase === PhaseType.APPROVED || phase === PhaseType.SENDING
                    ? 'border-2 border-primary-500'
                    : 'border-none'}`, children: [(phase === PhaseType.APPROVING || phase === PhaseType.APPROVED) && (_jsxs("button", { disabled: phase !== PhaseType.APPROVED, type: "submit", className: "button relative action-button", onClick: async () => {
                            try {
                                await executeTrade();
                            }
                            catch (error) {
                                console.log(error);
                            }
                        }, "aria-label": "Send", "aria-disabled": phase !== PhaseType.APPROVED, children: [_jsx("img", { src: buttonCheck, alt: "arrow", className: "inline ml-1", width: "1.25rem", height: "1.25rem" }), _jsx("span", { children: "Send" })] })), phase === PhaseType.SENDING && (_jsxs("div", { className: "flex", children: [_jsx("div", { className: "loader-modal mr-1" }), _jsx("div", { className: "text-label_l font-medium dark:text-neutral-0", children: "Please confirm on your wallet" })] })), phase === PhaseType.SENT && (_jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: isDarkMode ? checkDark : check, alt: "check", style: {
                                    width: `1.25rem`,
                                    height: `1.35rem`,
                                } }), _jsx("div", { className: "text-label_l font-medium dark:text-neutral-0", children: "Confirmed on your wallet" })] }))] })), _jsxs("div", { className: `hidden w-full mobile:flex flex-col justify-between items-center rounded-12 px-4 pb-6 pt-10 mt-2 bg-secondary-10 dark:bg-neutral-900 ${phase === PhaseType.SENT
                    ? 'border-2 border-primary-500'
                    : phase === PhaseType.DEPOSITING ||
                        phase === PhaseType.DEPOSITED ||
                        phase === PhaseType.DOWNWARD_ETH_SENDING ||
                        phase === PhaseType.DOWNWARD_ETH_SENT
                        ? 'border-none'
                        : 'opacity-50 border-none'}`, children: [_jsxs("div", { className: "flex flex-col justify-center items-center dark:text-neutral-0", children: [_jsxs("div", { className: "font-dosis flex justify-center items-end", children: [_jsx("div", { className: "text-countdown_number", children: String(time.minutes).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time font-medium", children: "min" }), _jsx("div", { className: "text-countdown_number", children: String(time.seconds).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time font-medium", children: "sec" })] }), _jsx("img", { src: phase === PhaseType.DEPOSITED ||
                                    phase === PhaseType.SENT ||
                                    phase === PhaseType.DOWNWARD_ETH_SENT
                                    ? processingGif
                                    : processingPause, alt: "Processing", style: {
                                    width: '150px',
                                    height: '50px',
                                }, className: "my-6" })] }), _jsxs("div", { className: "w-full flex justify-between items-start", children: [_jsxs("div", { className: "flex flex-col justify-center items-center", children: [_jsxs("div", { className: `relative border rounded-[30px] p-2 ${phase === PhaseType.DEPOSITED ||
                                            phase === PhaseType.SENT ||
                                            phase === PhaseType.DOWNWARD_ETH_SENT
                                            ? 'border-primary-500'
                                            : 'border-neutral-100'}`, children: [(phase === PhaseType.DEPOSITING ||
                                                phase === PhaseType.SENDING ||
                                                phase === PhaseType.DOWNWARD_ETH_SENDING) && (_jsx("div", { className: "loader-modal absolute top-0 left-0" })), (phase === PhaseType.DEPOSITED ||
                                                phase === PhaseType.SENT ||
                                                phase === PhaseType.DOWNWARD_ETH_SENT) && (_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                                    width: `20px`,
                                                    height: `20px`,
                                                } })), _jsx("img", { className: "inline", src: getImage('network/' +
                                                    getSelectableNetwork(selectedFromNetwork.networkID ??
                                                        currentTxInfo.sourceNetworkId).img), alt: getSelectableNetwork(selectedFromNetwork.networkID ??
                                                    currentTxInfo.sourceNetworkId).label, style: {
                                                    width: `2.5rem`,
                                                    height: `2.5rem`,
                                                } })] }), phase === PhaseType.DEPOSITED ||
                                        phase === PhaseType.SENT ||
                                        phase === PhaseType.DOWNWARD_ETH_SENT ? (_jsxs(_Fragment, { children: [_jsxs("a", { href: getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo?.acceptedTxHash) ??
                                                    getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo?.newTradeTxHash) ??
                                                    getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo?.burnTxHash) ??
                                                    '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 whitespace-nowrap", children: [getSelectableNetwork(selectedFromNetwork.networkID ??
                                                        currentTxInfo.sourceNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }), _jsxs("div", { className: "text-label_l font-medium dark:text-neutral-0", children: [currentTxInfo.amount
                                                        ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                            ? parseFloat(Number(Util.fromWei(currentTxInfo.amount)).toFixed(7))
                                                            : parseFloat(Number(Util.fromUSDC(currentTxInfo.amount)).toFixed(2))
                                                        : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                        ? 'USDC'
                                                        : networkManager
                                                            .getNonNativeNetworkChainIds()
                                                            .includes(selectedFromNetwork.networkID ??
                                                            currentTxInfo.sourceNetworkId)
                                                            ? 'WETH'
                                                            : 'ETH'] })] })) : (_jsx("div", { className: "text-text_link_l dark:text-neutral-0", children: getSelectableNetwork(selectedFromNetwork.networkID ??
                                            currentTxInfo.sourceNetworkId).label }))] }), _jsxs("div", { className: "flex flex-col justify-center items-center", children: [_jsxs("div", { className: `relative border rounded-[30px] p-2 ${currentTxInfo.acceptTxHash ||
                                            currentTxInfo.txhash ||
                                            currentTxInfo.mintTxHash
                                            ? 'border-primary-500'
                                            : 'border-neutral-100'}`, children: [(phase === PhaseType.DEPOSITED ||
                                                phase === PhaseType.SENT ||
                                                phase === PhaseType.DOWNWARD_ETH_SENT) &&
                                                !currentTxInfo.acceptTxHash &&
                                                !currentTxInfo.txhash &&
                                                !currentTxInfo.mintTxHash && (_jsx("div", { className: "loader-modal absolute top-0 left-0" })), (currentTxInfo.acceptTxHash ||
                                                currentTxInfo.txhash ||
                                                currentTxInfo.mintTxHash) && (_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                                    width: `20px`,
                                                    height: `20px`,
                                                } })), _jsx("img", { className: "inline", src: getImage('network/' +
                                                    getSelectableNetwork(selectedToNetwork.networkID ??
                                                        currentTxInfo.destinationNetworkId).img), alt: getSelectableNetwork(selectedToNetwork.networkID ??
                                                    currentTxInfo.destinationNetworkId).label, style: {
                                                    width: `2.5rem`,
                                                    height: `2.5rem`,
                                                } })] }), !currentTxInfo.acceptTxHash &&
                                        !currentTxInfo.txhash &&
                                        !currentTxInfo.mintTxHash && (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-text_link_l dark:text-neutral-0", children: getSelectableNetwork(selectedToNetwork.networkID ??
                                                    currentTxInfo.destinationNetworkId).label }), _jsxs("div", { className: "text-label_l font-medium dark:text-neutral-0", children: [currentTxInfo.amount && currentTxInfo.fee
                                                        ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                            ? parseFloat(Number(Util.fromWei(String(Number(currentTxInfo.amount) -
                                                                Number(currentTxInfo.fee)))).toFixed(7))
                                                            : parseFloat(Number(Util.fromUSDC(String(Number(currentTxInfo.amount) -
                                                                Number(currentTxInfo.fee)))).toFixed(2))
                                                        : '', ' ', currentTxInfo.amount && currentTxInfo.fee
                                                        ? currentTxInfo.tokenTypeIndex === Token.USDC
                                                            ? 'USDC'
                                                            : networkManager
                                                                .getNonNativeNetworkChainIds()
                                                                .includes(selectedToNetwork.networkID ??
                                                                currentTxInfo.destinationNetworkId)
                                                                ? 'WETH'
                                                                : 'ETH'
                                                        : ''] })] })), (currentTxInfo.acceptTxHash ||
                                        currentTxInfo.txhash ||
                                        currentTxInfo.mintTxHash) && (_jsxs(_Fragment, { children: [_jsxs("a", { href: getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo?.acceptTxHash) ??
                                                    getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo?.txhash) ??
                                                    getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo?.mintTxHash) ??
                                                    '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 whitespace-nowrap", children: [getSelectableNetwork(selectedToNetwork.networkID ??
                                                        currentTxInfo.destinationNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }), _jsxs("div", { className: "text-label_l font-medium dark:text-neutral-0", children: [currentTxInfo.amount && currentTxInfo.fee
                                                        ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                            ? parseFloat(Number(Util.fromWei(String(Number(currentTxInfo.amount) -
                                                                Number(currentTxInfo.fee)))).toFixed(7))
                                                            : parseFloat(Number(Util.fromUSDC(String(Number(currentTxInfo.amount) -
                                                                Number(currentTxInfo.fee)))).toFixed(2))
                                                        : '', ' ', tradeTokenTypeIndex === Token.USDC ? 'USDC' : 'ETH'] })] }))] })] })] }), _jsxs("div", { className: `mobile:hidden w-full flex justify-between items-center rounded-12 p-6 pt-10 mt-2 bg-secondary-10 dark:bg-neutral-900 ${phase === PhaseType.SENT
                    ? 'border-2 border-primary-500'
                    : phase === PhaseType.DEPOSITING ||
                        phase === PhaseType.DEPOSITED ||
                        phase === PhaseType.DOWNWARD_ETH_SENDING ||
                        phase === PhaseType.DOWNWARD_ETH_SENT
                        ? 'border-none'
                        : 'opacity-50 border-none'}`, children: [_jsxs("div", { className: "flex flex-col justify-center items-center", children: [_jsxs("div", { className: `relative border rounded-[30px] p-2 mb-2 ${phase === PhaseType.DEPOSITED ||
                                    phase === PhaseType.SENT ||
                                    phase === PhaseType.DOWNWARD_ETH_SENT
                                    ? 'border-primary-500'
                                    : 'border-neutral-100'}`, children: [(phase === PhaseType.DEPOSITING ||
                                        phase === PhaseType.SENDING ||
                                        phase === PhaseType.DOWNWARD_ETH_SENDING) && (_jsx("div", { className: "loader-modal absolute top-0 left-0" })), (phase === PhaseType.DEPOSITED ||
                                        phase === PhaseType.SENT ||
                                        phase === PhaseType.DOWNWARD_ETH_SENT) && (_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                            width: `1.188rem`,
                                            height: `1.188rem`,
                                        } })), _jsx("img", { className: "inline", src: getImage('network/' +
                                            getSelectableNetwork(selectedFromNetwork.networkID ??
                                                currentTxInfo.sourceNetworkId).img), alt: getSelectableNetwork(selectedFromNetwork.networkID ??
                                            currentTxInfo.sourceNetworkId).label, style: {
                                            width: `2.5rem`,
                                            height: `2.5rem`,
                                        } })] }), phase === PhaseType.DEPOSITED ||
                                phase === PhaseType.SENT ||
                                phase === PhaseType.DOWNWARD_ETH_SENT ? (_jsxs(_Fragment, { children: [_jsxs("a", { href: getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo?.acceptedTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo?.newTradeTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.sourceNetworkId), currentTxInfo?.burnTxHash) ??
                                            '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 whitespace-nowrap", children: [getSelectableNetwork(selectedFromNetwork.networkID ??
                                                currentTxInfo.sourceNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }), _jsxs("div", { className: "text-label_l dark:text-neutral-0", children: [currentTxInfo.amount
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(currentTxInfo.amount)).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(currentTxInfo.amount)).toFixed(2))
                                                : '', ' ', currentTxInfo.tokenTypeIndex === Token.USDC
                                                ? 'USDC'
                                                : networkManager
                                                    .getNonNativeNetworkChainIds()
                                                    .includes(selectedFromNetwork.networkID ??
                                                    currentTxInfo.sourceNetworkId)
                                                    ? 'WETH'
                                                    : 'ETH'] })] })) : (_jsx("div", { className: "text-text_link_l dark:text-neutral-0", children: getSelectableNetwork(selectedFromNetwork.networkID ??
                                    currentTxInfo.sourceNetworkId).label }))] }), _jsxs("div", { className: "flex flex-col justify-center items-center dark:text-neutral-0", children: [_jsx("img", { src: phase === PhaseType.DEPOSITED ||
                                    phase === PhaseType.SENT ||
                                    phase === PhaseType.DOWNWARD_ETH_SENT
                                    ? processingGif
                                    : processingPause, alt: "Processing", style: {
                                    width: '12.5rem',
                                    height: '3.75rem',
                                } }), _jsxs("div", { className: "font-dosis flex justify-center items-end", children: [_jsx("div", { className: "text-countdown_number", children: String(time.minutes).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time", children: "min" }), _jsx("div", { className: "text-countdown_number", children: String(time.seconds).padStart(2, '0') }), _jsx("div", { className: "text-countdown_time", children: "sec" })] })] }), _jsxs("div", { className: "flex flex-col justify-center items-center", children: [_jsxs("div", { className: `relative border rounded-[30px] p-2 ${currentTxInfo.acceptTxHash ||
                                    currentTxInfo.txhash ||
                                    currentTxInfo.mintTxHash
                                    ? 'border-primary-500'
                                    : 'border-neutral-100'}`, children: [(phase === PhaseType.DEPOSITED ||
                                        phase === PhaseType.SENT ||
                                        phase === PhaseType.DOWNWARD_ETH_SENT) &&
                                        !currentTxInfo.acceptTxHash &&
                                        !currentTxInfo.txhash &&
                                        !currentTxInfo.mintTxHash && (_jsx("div", { className: "loader-modal absolute top-0 left-0" })), (currentTxInfo.acceptTxHash ||
                                        currentTxInfo.txhash ||
                                        currentTxInfo.mintTxHash) && (_jsx("img", { className: "absolute top-0 left-0", src: isDarkMode ? checkDark : check, alt: "check", style: {
                                            width: `1.188rem`,
                                            height: `1.188rem`,
                                        } })), _jsx("img", { className: "inline", src: getImage('network/' +
                                            getSelectableNetwork(selectedToNetwork.networkID ??
                                                currentTxInfo.destinationNetworkId).img), alt: getSelectableNetwork(selectedToNetwork.networkID ??
                                            currentTxInfo.destinationNetworkId).label, style: {
                                            width: `2.5rem`,
                                            height: `2.5rem`,
                                        } })] }), !currentTxInfo.acceptTxHash &&
                                !currentTxInfo.txhash &&
                                !currentTxInfo.mintTxHash && (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-text_link_l dark:text-neutral-0", children: getSelectableNetwork(selectedToNetwork.networkID ??
                                            currentTxInfo.destinationNetworkId).label }), _jsxs("div", { className: "text-label_l dark:text-neutral-0", children: [currentTxInfo.amount && currentTxInfo.fee
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(2))
                                                : '', ' ', currentTxInfo.amount && currentTxInfo.fee
                                                ? currentTxInfo.tokenTypeIndex === Token.USDC
                                                    ? 'USDC'
                                                    : networkManager
                                                        .getNonNativeNetworkChainIds()
                                                        .includes(selectedToNetwork.networkID ??
                                                        currentTxInfo.destinationNetworkId)
                                                        ? 'WETH'
                                                        : 'ETH'
                                                : ''] })] })), (currentTxInfo.acceptTxHash ||
                                currentTxInfo.txhash ||
                                currentTxInfo.mintTxHash) && (_jsxs(_Fragment, { children: [_jsxs("a", { href: getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo?.acceptTxHash) ??
                                            getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo?.txhash) ??
                                            getExplorerUrl(String(currentTxInfo.destinationNetworkId), currentTxInfo?.mintTxHash) ??
                                            '', target: "_blank", rel: "noreferrer", className: "underline text-text_link_l hover:no-underline dark:text-neutral-0 whitespace-nowrap mt-2", children: [getSelectableNetwork(selectedToNetwork.networkID ??
                                                currentTxInfo.destinationNetworkId).label, _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }), _jsxs("div", { className: "text-label_l dark:text-neutral-0", children: [currentTxInfo.amount && currentTxInfo.fee
                                                ? currentTxInfo.tokenTypeIndex == Token.ETH
                                                    ? parseFloat(Number(Util.fromWei(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(7))
                                                    : parseFloat(Number(Util.fromUSDC(String(Number(currentTxInfo.amount) -
                                                        Number(currentTxInfo.fee)))).toFixed(2))
                                                : '', ' ', tradeTokenTypeIndex === Token.USDC ? 'USDC' : 'ETH'] })] }))] })] })] }));
};
export default ProcessingModalContent;
