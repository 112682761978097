import { blockExplorers, loadingDarkModeImages, loadingImages, NETWORKS, } from '@/constants';
export const getImage = (path) => {
    const image = require(`@/assets/new-icon/${path}`);
    return image.default;
};
export const fetchRandomLoadingImage = (isDarkMode) => {
    const images = isDarkMode ? loadingDarkModeImages : loadingImages;
    if (images.length > 0) {
        const url = images[Math.floor(Math.random() * images.length)];
        const randomImage = require('@/assets/animation/' + url);
        return randomImage.default;
    }
};
export const fetchRandomLoadingImageData = (isDarkMode) => {
    const images = isDarkMode ? loadingDarkModeImages : loadingImages;
    if (images.length > 0) {
        const url = images[Math.floor(Math.random() * images.length)];
        const randomImage = require('@/assets/animation/' + url);
        const data = {
            url: randomImage.default,
        };
        if (url === 'family-dark-mode.gif' || url === 'family.gif') {
            data['width'] = '24.7rem';
            data['height'] = '4.2rem';
            data['shouldMargin'] = true;
        }
        else if (url === 'twins-dark-mode.gif' || url === 'twins.gif') {
            data['width'] = '15rem';
            data['height'] = '15rem';
            data['shouldMargin'] = true;
        }
        else {
            data['width'] = '17rem';
            data['height'] = '17rem';
            data['shouldMargin'] = false;
        }
        return data;
    }
};
export const isMobileDevice = () => {
    return window.innerWidth <= 768;
};
export const getSelectableNetwork = (id) => {
    return NETWORKS.find((network) => network.networkID === id) || NETWORKS[0];
};
export const isSupportedNetwork = (id) => {
    return NETWORKS.some((network) => network.networkID === id);
};
export const formatNumberWithFourDecimalPlaces = (numberStr) => {
    const number = parseStringToNumber(numberStr);
    // Rounded down to four decimal places
    const factor = Math.pow(10, 4);
    const formattedNumber = Math.floor(number * factor) / factor;
    const trimmedNumber = formattedNumber.toString();
    return trimmedNumber;
};
export const parseStringToNumber = (numberStr) => {
    if (numberStr === '')
        return 0;
    const number = parseFloat(numberStr);
    if (isNaN(number)) {
        return 0;
    }
    return number;
};
export const isValidJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
};
export const shortenAddress = (address, length) => {
    return address.slice(0, length) + '...' + address.slice(-4);
};
export const convertUndefinedToZero = (_x) => {
    let x;
    if (typeof _x !== 'number') {
        x = 0;
    }
    else {
        x = _x;
    }
    return x;
};
export const getExplorerUrl = (networkId, _hash) => {
    if (!_hash) {
        return null;
    }
    const r = Object.entries(blockExplorers).find(([key]) => key == networkId);
    if (r != null) {
        return r[1] + 'tx/' + _hash;
    }
    return null;
};
export const calculateTradeTime = (firstTimestamp, secondTxTimestamp, estimateTime) => {
    if (!firstTimestamp || !secondTxTimestamp) {
        return { minutes: estimateTime.minutes, seconds: estimateTime.seconds };
    }
    const diffInMs = secondTxTimestamp - firstTimestamp;
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;
    return { minutes, seconds };
};
