import { useEffect, useState } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { NETWORKS } from '@/constants';
import { useSelectedNetworkContext } from '@/context';
import { PheasantNetworkBridgeChild, Web3ServiceBrowserNew as Web3Service, BondManager, PheasantNetworkParameters, Token, Util, } from '@pheasant-network/pheasant-sdk';
import { getSelectableNetwork } from '@/utils';
import { useNetworkManagerContext } from '@/context/NetworkManagerContext';
let web3;
export const useInstance = () => {
    const { chain: currentChain } = useAccount();
    const { isError: isSwitchError } = useSwitchChain();
    const { address, isConnected } = useAccount();
    const [thresholds, setThresholds] = useState({});
    const [usdcThresholds, setUsdcThresholds] = useState({});
    const [recipient, setRecipient] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);
    const [bridge_contract_for_call, setBridge_contract_for_call] = useState(null);
    const { setSelectedNetwork } = useSelectedNetworkContext();
    const [allContractAddressList, setAllContractAddressList] = useState({});
    const [isTemporarySetting, setIsTemporarySetting] = useState(false);
    const initialAllForCalls = NETWORKS.reduce((acc, network) => {
        if (network.id !== 0) {
            acc[network.id] = {};
        }
        return acc;
    }, {});
    const [allForCalls, setAllForCalls] = useState(initialAllForCalls);
    // custom hook
    const networkManager = useNetworkManagerContext();
    useEffect(() => {
        if (isConnected) {
            initialize();
        }
    }, [isConnected]);
    // When network is switched, initialize all
    useEffect(() => {
        if (currentChain) {
            setSelectedNetwork(currentChain && currentChain?.id
                ? getSelectableNetwork(currentChain.id)
                : NETWORKS[0]);
        }
    }, [currentChain, isSwitchError, setSelectedNetwork]);
    const initialize = async () => {
        //let ethereumEndpoint = process.env.REACT_APP_GOERLI_ENDPOINT
        let ethereumEndpoint = process.env.REACT_APP_SEPOLIA_ENDPOINT;
        web3 = await Web3Service.init(ethereumEndpoint);
        setTemporarySetting();
        await initializeAll();
    };
    const initializeAll = async () => {
        try {
            console.log('initializeAll');
            setIsInitialized(false);
            await initializeCallEndpoint();
            console.log('Finish the initialization');
            setRecipient(address);
            setIsInitialized(true);
            //setDisputableList(Array(Object.values(tradeList).length).fill(true)) //initialize
        }
        catch (error) {
            console.log(error);
        }
    };
    const initializeSepolia = async () => {
        const web3_mainnet = await Web3Service.init(process.env.REACT_APP_SEPOLIA_ENDPOINT);
        allForCalls[11]['web3'] = web3_mainnet;
    };
    const initializeMainnet = async () => {
        let ethereumEndpoint;
        ethereumEndpoint = process.env.REACT_APP_MAINNET_ENDPOINT;
        const web3_mainnet = await Web3Service.init(ethereumEndpoint);
        Object.assign(allForCalls, {
            1: { web3: web3_mainnet },
        });
    };
    const initializeCallEndpoint = async () => {
        const polygonNetworkId = 80002;
        // const optimismNetworkId: number = 420
        // const arbitrumNetworkId: number = 421613
        const optimismNetworkId = 11155420;
        const arbitrumNetworkId = 421614;
        const scrollNetworkId = 534351;
        const zkSyncNetworkId = 300;
        //const baseNetworkId: number = 84531
        const baseNetworkId = 84532;
        const polygonZkEvmNetworkId = 2442;
        const lineaNetworkId = 59141;
        const taikoNetworkId = 167009;
        const mantleNetworkId = 5003;
        const modeNetworkId = 919;
        const xLayerNetworkId = 195;
        const morphNetworkId = 2810;
        const zircuitNetworkId = 48899;
        const fhenixNetworkId = 8008135;
        const unichainSepoliaNetworkId = 1301;
        const minatoNetworkId = 1946;
        const polygonEndpoint = process.env.REACT_APP_AMOY_ENDPOINT;
        // const optimismEndpoint: string =
        //   process.env.REACT_APP_OPTIMISM_GOERLI_ENDPOINT
        // const arbitrumEndpoint: string =
        //   process.env.REACT_APP_ARBITRUM_GOERLI_ENDPOINT
        const optimismEndpoint = process.env.REACT_APP_OPTIMISM_SEPOLIA_ENDPOINT;
        const arbitrumEndpoint = process.env.REACT_APP_ARBITRUM_SEPOLIA_ENDPOINT;
        const scrollEndpoint = process.env.REACT_APP_SCROLL_TESTNET_ENDPOINT;
        const zkSyncEndpoint = process.env.REACT_APP_ZKSYNC_SEPOLIA_ENDPOINT;
        // const baseEndpoint: string = process.env.REACT_APP_BASE_GOERLI_ENDPOINT
        const baseEndpoint = process.env.REACT_APP_BASE_SEPOLIA_ENDPOINT;
        const polygonZkEvmEndpoint = process.env.REACT_APP_CARDONA_ENDPOINT;
        const lineaEndpoint = process.env.REACT_APP_LINEA_SEPOLIA_ENDPOINT;
        const taikoEndpoint = process.env.REACT_APP_TAIKO_TESTNET_ENDPOINT;
        const mantleEndpoint = process.env.REACT_APP_MANTLE_TESTNET_ENDPOINT;
        const modeEndpoint = process.env.REACT_APP_MODE_TESTNET_ENDPOINT;
        const xLayerEndpoint = process.env.REACT_APP_XLAYER_TESTNET_ENDPOINT;
        const morphEndpoint = process.env.REACT_APP_MORPH_HOLESKY_ENDPOINT;
        const zircuitEndpoint = process.env.REACT_APP_ZIRCUIT_TESTNET_ENDPOINT;
        const fhenixEndpoint = process.env.REACT_APP_FHENIX_TESTNET_ENDPOINT;
        const unichainSepoliaEndpoint = process.env.REACT_APP_UNICHAIN_SEPOLIA_ENDPOINT;
        const minatoEndpoint = process.env.REACT_APP_MINATO_ENDPOINT;
        const ethereumProcedures = async () => {
            try {
                await initializeSepolia();
                await initializeMainnet();
            }
            catch (error) {
                console.error('ethereumProcedures', error);
            }
        };
        const polygonProcedures = async () => {
            try {
                const web3_polygon = await Web3Service.init(polygonEndpoint);
                const id = 3;
                const networkId = polygonNetworkId;
                allForCalls[3]['web3'] = web3_polygon;
                allContractAddressList['polygon'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                const polygon_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_polygon, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[3]['bridgeContract'] = polygon_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_polygon, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[3]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_polygon, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[3]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const optimismProcedures = async () => {
            try {
                const web3_optimism = await Web3Service.init(optimismEndpoint);
                const id = 2;
                const networkId = optimismNetworkId;
                allContractAddressList['optimism'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_optimism;
                const optimism_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_optimism, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = optimism_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_optimism, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_optimism, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const arbitrumProcedures = async () => {
            try {
                const web3_arbitrum = await Web3Service.init(arbitrumEndpoint);
                const id = 4;
                const networkId = arbitrumNetworkId;
                allContractAddressList['arbitrum'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_arbitrum;
                const arbitrum_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_arbitrum, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = arbitrum_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_arbitrum, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_arbitrum, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const scrollProcedures = async () => {
            try {
                const web3_scroll = await Web3Service.init(scrollEndpoint);
                const id = 5;
                const networkId = scrollNetworkId;
                allContractAddressList['scroll'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_scroll;
                const scroll_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_scroll, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = scroll_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_scroll, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_scroll, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const zkSyncProcedures = async () => {
            try {
                const web3_zkSync = await Web3Service.init(zkSyncEndpoint);
                const id = 6;
                const networkId = zkSyncNetworkId;
                allContractAddressList['zkSync'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_zkSync;
                const zkSync_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_zkSync, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = zkSync_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_zkSync, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_zkSync, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const baseProcedures = async () => {
            try {
                const web3_base = await Web3Service.init(baseEndpoint);
                const id = 7;
                const networkId = baseNetworkId;
                allContractAddressList['base'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_base;
                const base_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_base, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = base_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_base, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_base, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const polygonZkEvmProcedures = async () => {
            try {
                const web3_polygonZkEvm = await Web3Service.init(polygonZkEvmEndpoint);
                const id = 8;
                const networkId = polygonZkEvmNetworkId;
                allContractAddressList['polygonZkEvm'] =
                    await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_polygonZkEvm;
                const polygonZkEvm_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_polygonZkEvm, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] =
                    polygonZkEvm_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_polygonZkEvm, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_polygonZkEvm, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const lineaProcedures = async () => {
            try {
                const web3_linea = await Web3Service.init(lineaEndpoint);
                const id = 9;
                const networkId = lineaNetworkId;
                allContractAddressList['linea'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_linea;
                const linea_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_linea, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = linea_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_linea, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_linea, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const taikoProcedures = async () => {
            try {
                const web3_taiko = await Web3Service.init(taikoEndpoint);
                const id = 10;
                const networkId = taikoNetworkId;
                allContractAddressList['taiko'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_taiko;
                const taiko_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_taiko, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = taiko_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_taiko, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_taiko, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const mantleProcedures = async () => {
            try {
                const web3_mantle = await Web3Service.init(mantleEndpoint);
                const id = 12;
                const networkId = mantleNetworkId;
                allContractAddressList['mantle'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_mantle;
                const mantle_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_mantle, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = mantle_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_mantle, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_mantle, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const modeProcedures = async () => {
            try {
                const web3_mode = await Web3Service.init(modeEndpoint);
                const networkId = modeNetworkId;
                const id = 13;
                allContractAddressList['mode'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_mode;
                const mantle_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_mode, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = mantle_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_mode, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_mode, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const xLayerProcedures = async () => {
            try {
                const web3 = await Web3Service.init(xLayerEndpoint);
                const networkId = xLayerNetworkId;
                const id = 14;
                const name = 'xLayer';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const morphProcedures = async () => {
            try {
                const web3 = await Web3Service.init(morphEndpoint);
                const networkId = morphNetworkId;
                const id = 16;
                const name = 'morph';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const zircuitProcedures = async () => {
            try {
                const web3 = await Web3Service.init(zircuitEndpoint);
                const networkId = zircuitNetworkId;
                const id = 17;
                const name = 'zircuit';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const fhenixProcedures = async () => {
            try {
                const web3 = await Web3Service.init(fhenixEndpoint);
                const networkId = fhenixNetworkId;
                const id = 18;
                const name = 'fhenix';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const unichainSepoliaProcedures = async () => {
            try {
                const web3 = await Web3Service.init(unichainSepoliaEndpoint);
                const networkId = unichainSepoliaNetworkId;
                const id = 19;
                const name = 'unichainSepolia';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const minatoProcedures = async () => {
            try {
                const web3 = await Web3Service.init(minatoEndpoint);
                const networkId = minatoNetworkId;
                const id = 20;
                const name = 'minato';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        let procedureList = [];
        procedureList = [
            ethereumProcedures(),
            polygonProcedures(),
            optimismProcedures(),
            scrollProcedures(),
            lineaProcedures(),
            arbitrumProcedures(),
            zkSyncProcedures(),
            baseProcedures(),
            //polygonZkEvmProcedures(),
            //mantleProcedures(),
            modeProcedures(),
            taikoProcedures(),
            xLayerProcedures(),
            morphProcedures(),
            zircuitProcedures(),
            fhenixProcedures(),
            unichainSepoliaProcedures(),
            minatoProcedures(),
        ];
        try {
            await Promise.all(procedureList);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getThresholds = async (parameterForCall, networkCode, token) => {
        const threshold = await parameterForCall.tradeThreshold(networkCode, token);
        const minimumAmount = await parameterForCall.tradeMinimumAmount(networkCode, token);
        return {
            thresholdAmount: token === Token.ETH
                ? parseFloat(await Util.fromWei(threshold))
                : parseFloat(await Util.fromUSDC(threshold)),
            minimumAmount: token === Token.ETH
                ? parseFloat(await Util.fromWei(minimumAmount))
                : parseFloat(await Util.fromUSDC(minimumAmount)),
        };
    };
    const setTemporarySetting = () => {
        setIsTemporarySetting(false);
    };
    const getAllContractAddressList = async (networkId, token) => {
        const tokenAddressList = await networkManager.getAllNetworkContractInfo('all');
        return tokenAddressList[networkId][token];
    };
    const getLatestContractAddress = async (networkId, token) => {
        const tokenAddressList = await getAllContractAddressList(networkId, token);
        if (!Array.isArray(tokenAddressList)) {
            return tokenAddressList;
        }
        return tokenAddressList[tokenAddressList.length - 1];
    };
    return {
        web3,
        bridge_contract_for_call,
        getLatestContractAddress,
        setTemporarySetting,
        isTemporarySetting,
        isInitialized,
        thresholds,
        usdcThresholds,
        allForCalls,
        recipient,
        setRecipient,
        allContractAddressList,
    };
};
